/* eslint-disable react/no-unknown-property */
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';

const Contact = () => {
  return (
    <section id="contact">
      <Container>
        <Title title="Contact" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">Would you like to work with me? Awesome!</p>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              action="/thanks"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <p>
                <input
                  style={{
                    width: '100%',
                    maxWidth: '450px',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px',
                  }}
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </p>
              <p>
                <textarea
                  style={{
                    height: '200px',
                    width: '100%',
                    maxWidth: '450px',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px',
                  }}
                  name="message"
                  placeholder="Message"
                  required
                />
              </p>
              <p>
                <button className="cta-btn cta-btn--resume" type="submit">
                  Let&apos;s Talk
                </button>
              </p>
            </form>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
