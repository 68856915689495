import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          <a
            key="github"
            href="https://github.com/zdeager"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa fa-github fa-inverse" />
          </a>
          <a
            key="linkedin"
            href="https://www.linkedin.com/in/zack-eager-37b38a69/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa fa-linkedin fa-inverse" />
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
