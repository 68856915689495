import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const projects = [
    {
      id: 'icitizen',
      img: 'ic.png',
      title: 'icitizen',
      info: 'icitizen connects people with their elected officials and the organizations they care about.',
      info2:
        'Using icitizen, people vote on and promote issues and policies that affect their lives. By working together online, people and their leaders build stronger, more connected communities in the real world.',
      info3: 'Role: co-founder and lead developer',
      info4: 'Tech: React + AWS',
      url: 'https://icitizen.com/',
      repo: '',
    },
    {
      id: 'FacilityChex',
      img: 'facilitychex.png',
      title: 'FacilityChex',
      info: 'FacilityChex is a ticketing and invoicing system for waste managemnt of autoshops.',
      info2:
        'FacilityChex additionally provides insight into performance of clients and suggests predictive maintenance through data.',
      info3: 'Role: lead developer',
      info4: 'Tech: React + AWS',
      url: 'https://facilitychex.com/',
      repo: '',
    },
    {
      id: 'civicvoice',
      img: 'civicvoice.png',
      title: 'Civic Voice',
      info: 'Civic Voice connects consituents to their organizations via Amazon Alexa.',
      info2:
        'Organizations such as schools or towns provide information (calendar events, FAQs, current events) that can be accessed via an Alexa skill.',
      info3: 'Role: co-founder and lead developer',
      info4: 'Tech: React + AWS (Alexa SDK)',
      url: 'https://civicvoice.us/',
      repo: '',
    },
    {
      id: 'ViSTA',
      img: 'councilrock.jpg',
      title: 'ViSTA',
      info: 'ViSTA is an out-of-the-box IIoT cloud platform or on-premises solution for rapid development, management, and scaling of IIoT projects.',
      info2: '',
      info3: 'Role: developer',
      info4: 'Tech: Angular + AWS/on-prem',
      url: 'https://councilrock.com/vista/',
      repo: '',
    },
  ];
  const research = [
    {
      id: 'flends',
      img: 'flends.jpg',
      title: 'Flends',
      info: 'The methods for creating transition surfaces, as implemented in many solid modelers, are restrictive and fragile. Flends are a more robust and flexible method for creating transition surfaces using B-spline surfaces. These methods are solid modeler agnostic.',
      info2: '',
      info3: 'Tech: Engineering Sketch Pad (written in C/C++)',
      info4: (
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://acdl.mit.edu/ESP/Publications/AIAApaper2019-1717.pdf"
          >
            Flends
          </a>
          ,&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://zde-res.s3.us-east-2.amazonaws.com/approx_intersection.pdf"
          >
            Approx. Intersections
          </a>
        </>
      ),
      info5: 'Presentations: AIAA Scitech 2019',
      url: 'https://acdl.mit.edu/ESP/',
      repo: '',
    },
    {
      id: 'forge',
      img: 'forge.png',
      title: 'Airport BIM (ABIM)',
      info: 'Value creation is a recurring issue in airport projects, and fast realization of technology implementation is dependent on creating synergies between digital technologies by breaking the information siloes. Connecting already existing digital technologies to streamline information flow is very important.',
      info2: '',
      info3: 'Tech: React + AWS + Autodesk Forge',
      info4: (
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://aps.autodesk.com/blog/guest-blog-bim-enabled-digital-transformation-airports"
          >
            BIM-enabled airports
          </a>
        </>
      ),
      info5: 'Presentations: AU Las Vegas 2019',
      url: 'https://github.com/zdeager/forge-serverless',
      repo: '',
    },
    {
      id: 'comm',
      img: 'comm.png',
      title: 'Community Detection',
      info: 'The modularity quality function commonly employed for finding community structure in complex networks is generalized in this article to a one-parameter family of quality functions. We define an adjustable efficiency-modularity parameter that can detect either community or anticommunity structure with a single unified algorithm.',
      info2: '',
      info3: 'Tech: NetworkX (python) + Gephi',
      info4: (
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://academic.oup.com/comnet/article-abstract/5/1/70/2555361?redirectedFrom=fulltext"
          >
            Community detection
          </a>
          ,&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://zde-res.s3.us-east-2.amazonaws.com/Augmentation_for_efficiency.pdf"
          >
            Augmentations
          </a>
        </>
      ),
      info5: 'Presentations: 2015 Joint Mathematics Meetings, MAA Seaway Section',
      url: 'https://github.com/zdeager/comm_detection',
      repo: '',
    },
  ];
  const rec = [
    {
      id: 'esea',
      img: 'esea.png',
      title: 'ESEA Analyzer',
      info: 'ESEA Analyzer is a browser extension that lets you review and analyze CS:GO demos. The extension allows you to replay rounds, inspect utility, create heatmaps, etc.',
      info2: '',
      info3: 'Tech: React + AWS + Docker + awpy (Golang)',
      info4: '',
      url: 'https://www.youtube.com/watch?v=eF0A4QK1RUo',
      repo: '',
    },
    {
      id: 'iso',
      img: 'iso.png',
      title: 'isoArena',
      info: 'isoArena is the beginnings of an isometric, tactical, turn-based strategy game.',
      info2: '',
      info3: 'Tech: SDL (C/C++) + Lua',
      info4: '',
      url: '',
      repo: 'https://github.com/zdeager/isoarena',
    },
  ];

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Projects" />
          {projects.map((project) => {
            const { title, info, info2, info3, info4, url, repo, img, id } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <p>{info}</p>
                        <p>{info2 || ''}</p>
                        <p style={{ fontStyle: 'italic' }} className="mb-4">
                          {info3 || ''}
                          <br />
                          {info4 || ''}
                        </p>
                      </div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#!'}
                      >
                        See Live
                      </a>

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}

          <Title title="Research" />
          {research.map((project) => {
            const { title, info, info2, info3, info4, info5, url, img, id } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <p>{info}</p>
                        <p>{info2 || ''}</p>
                        <p style={{ fontStyle: 'italic' }} className="mb-4">
                          {info3 || ''}
                          <br />
                          Publications: {info4 || ''}
                          <br />
                          {info5 || ''}
                        </p>
                      </div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#!'}
                      >
                        Source Code
                      </a>
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}

          <Title title="Recreational" />
          {rec.map((project) => {
            const { title, info, info2, info3, info4, repo, url, img, id } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <p>{info}</p>
                        <p>{info2 || ''}</p>
                        <p style={{ fontStyle: 'italic' }} className="mb-4">
                          {info3 || ''}
                          <br />
                          {info4 || ''}
                        </p>
                      </div>
                      {url && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={url || '#!'}
                        >
                          See Live
                        </a>
                      )}

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className={url ? 'cta-btn text-color-main' : 'cta-btn cta-btn--hero'}
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
